<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="12" class="text-left"><h4>Create Template Notification</h4></CCol> 
            </CRow>     
          </CCardHeader>
          <CCardBody> 
            <CForm>
              <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
              </CRow>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>

                <CInput
                  type="text" 
                  label="Template Name"
                  v-model="name"
                  placeholder="Type Name"                               
                  horizontal                
                />

                <CInput
                  type="text" 
                  label="Title"
                  v-model="title"
                  placeholder="Type Title"
                  maxlength="40"  
                  description="Maximum 40 characters"              
                  horizontal                
                />

                <CTextarea
                    label="Preview"
                    v-model="preview"  
                    placeholder="Type Preview..."
                    maxlength="140"  
                    description="Maximum 140 characters"
                    horizontal
                    rows="2"               
                />

                <CTextarea
                    label="Body"
                    v-model="body"  
                    placeholder="Type Body..."
                    maxlength="180"  
                    description="Maximum 180 characters"
                    horizontal
                    rows="5"               
                /> 
             
                <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9">
                    <b-button-group>
                    <router-link to="/notif/template" tag="b-button">Cancel</router-link> 
                    <b-button style="width:100px;" v-on:click="actionSave()" variant="primary">Save</b-button>
                    </b-button-group>
                  </b-col>                  
                </b-row><br/>              
            
           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AccountService from '@/api/AccountService.js';
 import moment from 'moment';
 
  export default { 
    name: 'create_template',
    components: { 
    },     
    data() { var date = new Date();
      return { 
          spinner:false,          
          name:'',
          body:'',
          preview:'',
          title:'',
          seen:false,          
          msg :'',
          color:''
        };      
    },
    created () { 
      
    },
    methods: {

      actionSave(){
          
        let params = {};  var validate = [];

        params['body'] = this.body;
        params['preview'] = this.preview;
        params['templateName'] = this.name;
        params['title'] = this.title;

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){   //console.log(params);
            params['templateId'] = 0;
            this.saveNotifTemplate(params); 
        } else {
           this.msg   = 'Data Not Complete!'; 
           this.color = 'warning'; this.seen  = true;          
        } 
        setTimeout( () =>  this.seen=false , 5000);

      }, 

      saveNotifTemplate: function(params) { 
          AccountService.saveNotifTemplate(params).then(resp => {  console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Create New Template Successfully'; 
                this.color = 'success';  this.seen  = true;
                this.body = '';
                this.preview = '';
                this.name = '';
                this.title = '';
              } else {
                this.msg   = !resp.data.message ? "Create New Template Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                  
              }

              setTimeout( () =>  this.seen=false , 5000);
            
           }, error => {
            console.log('error');    
          });
      },          
    }
  }
</script>